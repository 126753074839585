<template>
  <div class="pageBox">
    <div class="pageTop">
      <div @click="goBack()"></div>
      <div>消息详情</div>
    </div>
    <div class="title">{{dataObj.title}}</div>
    <div class="time">{{dataObj.createTime}}</div>
    <div class="content">{{dataObj.data}}</div>
  </div>
</template>

<script>
import { messageInfo } from "../../../api/lookAt";
export default {
  data() {
    return {
      id: this.$route.query.id,
      dataObj: {}
    };
  },
  mounted() {
    messageInfo({ id: this.id }).then(res => {
      console.log(res, "详情");
      this.dataObj = res.data.data;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.pageTop {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.pageTop div:nth-child(1) {
  width: 48px;
  height: 48px;
  background-image: url("../../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.pageTop div:nth-child(2) {
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-left: 232px;
}
.title {
  width: 686px;
  font-size: 32px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 36px;
  margin-left: 32px;
  line-height: 48px;
}
.time {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 32px;
  margin-left: 32px;
}
.content {
  width: 686px;
  margin-top: 32px;
  margin-left: 32px;
  font-size: 32px;
  font-weight: 400;
  color: #222222;
  line-height: 72px;
}
</style>
